import { BuilderComponent } from "@builder.io/react"
import * as React from "react"
import { Helmet } from "react-helmet"
import Link from "../components/Link/Link"

function BlogArticleDetail({ pageContext }) {
  const { content } = pageContext

  return (
    <>
      <Helmet>
        <title>{content.data.title}</title>

        <meta name="description" content={content.data.description} />
        <meta name="og:image" content={content.data.image} />
      </Helmet>
      <BuilderComponent renderLink={Link} name="article" content={content} />
    </>
  )
}

export default BlogArticleDetail
JSON.stringify
